import axios from "axios";
import { API_BASE_URL } from "../config/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Instance = () => {
  const navigate = useNavigate();

  const instance = axios.create({
    baseURL: API_BASE_URL,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          navigate("/login");
          break;

        case 422:
          break;

        case 409:
          toast.error(error.response.data.message);
          break;

        case 500:
          break;

        default:
          break;
      }
      return Promise.reject(error);
    }
  );
  return { instance };
};

export default Instance;

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../../components/ui/sheet";
import { LuAlignLeft } from "react-icons/lu";

const NavigateTiles = () => {
  return (
    <Sheet>
      <SheetTrigger>
        <LuAlignLeft className="w-5 h-5 text-white" />
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>
            <div className="flex flex-row gap-2">
              <LuAlignLeft className="w-5 h-5 text-white" />
              <div className="font-inter font-semibold text-xl leading-5 tracking-widest text-white">
                UNIMAYT
              </div>
            </div>
          </SheetTitle>
          <SheetDescription>
            <ul className="space-y-4 text-white p-4 pt-8 text-lg">
              <li className="hover:text-unimayt">
                <a href="/home">Home</a>
              </li>
              <li className="hover:text-unimayt">
                <a href="/resources">Resources</a>
              </li>
              <li className="hover:text-unimayt">
                <a href="/about">About Us</a>
              </li>
              <li className="hover:text-unimayt">
                <a href="/journey">Journey</a>
              </li>
              <li className="hover:text-unimayt">
                <a href="/costCalculator">Cost Of Living</a>
              </li>
              <li className="hover:text-unimayt">
                <a href="/blog">Blog</a>
              </li>
              <li className="hover:text-unimayt">
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default NavigateTiles;

import { useLocation, useParams } from "react-router-dom";
import UseUsers from "../../../services/useUsers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BannerImage from "../../../assets/BannerImage.jpg";
import ReadMore from "../../../lib/ReadMore";
import { MdMessage } from "react-icons/md";
import UseChat from "../../../services/useChat";
import { useUserStore } from "../../../zustandStore/useUserStore";
import Footer from "../../layout/Footer";

const OtherProfile = () => {
  const { guid } = useParams<{ guid: string }>();
  const { getUsersbyId } = UseUsers();
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);
  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const setChatGuid = useUserStore((state) => state.setChatGuid);
  const queryClient = useQueryClient();
  const location = useLocation();
  const { chat_guid } = location.state;

  const {
    data: user,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["users", guid],
    queryFn: getUsersbyId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const { postStartChat } = UseChat();

  const StartChatMutation = useMutation({
    mutationFn: postStartChat,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      setChatRoomOpen(data.data);
      setSelectedUser(data.data);
    },
  });

  const SkeletonLoader = () => (
    <div className="w-full h-screen">
      <div className="animate-pulse flex flex-col space-y-6 h-full md:w-[70dvw] w-screen">
        <div className="relative w-full h-48 bg-gray-400/70 bg-cover bg-center">
          <div className="absolute -bottom-12 left-2 md:left-4 md:-bottom-28">
            <div className="md:w-40 md:h-40 w-28 h-28 bg-gray-400/70 rounded-full border-4 border-white"></div>
          </div>
        </div>

        <div className="flex items-center space-x-4 md:mx-48 mx-4 gap-x-2 pb-8">
          <div className="flex-1 space-y-4">
            <div className="h-4 bg-gray-400/70 rounded md:w-40 w-28 mx-28 md:mx-0"></div>
            <div className="space-y-2">
              <div className="h-3 bg-gray-400/70 rounded w-64"></div>
              <div className="h-3 bg-gray-400/70 rounded w-64"></div>
              <div className="h-3 bg-gray-400/70 rounded w-64"></div>
            </div>
          </div>
        </div>

        <div className="mb-4 bg-gray-400/70 p-2 rounded relative h-36"></div>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      {isLoading ? (
        <SkeletonLoader />
      ) : isError ? (
        // <p className="text-red-600">{error.message}</p>
        <SkeletonLoader />
      ) : (
        <>
          <div
            className="relative w-full h-48 bg-cover bg-center hover:opacity-80 transition-opacity duration-300"
            style={{
              backgroundImage: `url(${user?.data?.background_image || BannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: "unimayt",
            }}
          ></div>

          <div className="flex items-center md:mx-4 mx-3 gap-x-2 relative">
            <div className="relative group">
              <div className="md:w-44 md:h-44 w-28 h-28 bg-unimayt rounded-full relative top-1/2 transform -translate-y-1/2 lg:top-1/3 lg:-translate-y-1/3">
                {user?.user?.user_image && (
                  <img
                    src={user?.data?.user_image}
                    alt="Profile"
                    className="w-full h-full rounded-full object-cover"
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col w-full md:ml-4">
              <div className="flex justify-between items-center md:-mt-6 -mt-12">
                <h2 className="md:text-2xl text-lg font-bold md:-mt-8">{`${user?.user?.first_name} ${user?.user?.last_name}`}</h2>
                <div className="flex items-center md:gap-2 rounded p-1 font-semibold">
                  <div className="ml-auto cursor-pointer">
                    <MdMessage
                      className="text-unimayt mr-2 cursor-pointer"
                      onClick={() => {
                        if (chat_guid == null) {
                          StartChatMutation.mutate(user?.user?.guid);
                        } else {
                          setChatGuid(chat_guid);
                          setSelectedUser(user?.user);
                        }
                      }}
                      size={24}
                    />
                  </div>
                </div>
              </div>

              <div className="hidden md:block font-bold text-sm md:text-sm lg:mt-1">
                <div>
                  <span className="font-semibold">
                    {user?.user?.university_name
                      ? `${user.user.university_name} (${user.user.campus_location || "Location not provided"})`
                      : "Not yet applied for university"}
                  </span>
                </div>

                <div className="font-semibold">
                  {user?.user?.course_name ? (
                    <>
                      {user.user.course_name} {user.user.course_start_date}
                    </>
                  ) : (
                    <div className="text-gray-500">No course enrolled</div>
                  )}
                </div>

                {user?.user?.city_name && (
                  <div>
                    <span className="font-bold">Home Location:</span>{" "}
                    <span className="font-semibold">
                      {user?.user?.city_name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="block md:hidden text-md -mt-12 ml-4 mb-3 md:mb-0 md:ml-0 font-bold text-sm md:text-sm lg:mt-1">
            {user?.user?.university_name && user?.user?.campus_location && (
              <div>
                <span className="font-semibold">
                  {` ${user?.user?.university_name}  (${user?.user?.campus_location})`}
                </span>
              </div>
            )}

            {user?.user?.course_name && user?.user?.course_start_date && (
              <div>
                <span className="font-semibold">
                  {`${user?.user?.course_name}  (${user?.user?.course_start_date})`}
                </span>
              </div>
            )}

            {user?.user?.city_name && (
              <div>
                <span className="font-bold">Home Location:</span>{" "}
                <span className="font-semibold">{user?.user?.city_name}</span>
              </div>
            )}

            {user?.data?.city_name && (
              <div>
                <span className="font-bold">Home Location:</span>{" "}
                <span className="font-normal">{user?.data?.city_name}</span>
              </div>
            )}
          </div>
          <div className="border-t border-gray-400 lg:-mt-12"></div>
          <div className="flex flex-col px-3 items-start bg-Gray py-2 my-2 rounded-lg">
            <div className="w-full flex justify-between items-center ">
              <div className="text-md font-bold">Journey so Far</div>
              <div></div>
              <section className="mt-1">
                <div className="text-md font-normal max-h-24">
                  {user?.data?.bio ? (
                    <ReadMore
                      id={""}
                      amountOfWords={28}
                      text={user?.data?.bio}
                    />
                  ) : (
                    <div className="text-unimayt text-sm text-wrap text-ellipsis line-clamp-1 italic">
                      Bio coming soon!
                    </div>
                  )}
                </div>
              </section>
            </div>
            <div className="text-unimayt truncate italic text-sm mt-2">
              {`${user?.user?.first_name}`} hasn't added any milestones to their
              journey yet...
            </div>
          </div>
        </>
      )}
      <div className="w-full mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default OtherProfile;

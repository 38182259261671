import { useState } from "react";
import { Button } from "../components/ui/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Label } from "../components/ui/Label";
import { RadioGroup, RadioGroupItem } from "../components/ui/RadioGroup";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/Select";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import footer from "../components/layout/Footer";
import Footer from "../components/layout/Footer";

const countryCostIndices = {
  "United States": 1,
  "United Kingdom": 1.1,
  France: 0.95,
  Germany: 0.9,
  Japan: 1.2,
  Australia: 1.15,
  Canada: 0.95,
  Spain: 0.8,
  Italy: 0.85,
  Netherlands: 1,
};

const baseCosts = {
  housing: {
    shared: 500,
    nonShared: 1000,
  },
  food: {
    cookMostly: 250,
    eatOutOften: 500,
  },
  transportation: {
    publicTransport: 80,
    rideshare: 300,
  },
  entertainment: {
    movies: 100,
    clubbing: 300,
  },
};

const CostCalculator = () => {
  const [country, setCountry] = useState<
    keyof typeof countryCostIndices | undefined
  >();
  const [housing, setHousing] = useState<"shared" | "nonShared">("shared");
  const [food, setFood] = useState<"cookMostly" | "eatOutOften">("cookMostly");
  const [transportation, setTransportation] = useState<
    "publicTransport" | "rideshare"
  >("publicTransport");
  const [entertainment, setEntertainment] = useState<"movies" | "clubbing">(
    "movies"
  );
  const [monthlyCost, setMonthlyCost] = useState(0);

  const calculateCost = () => {
    if (!country) return;
    const countryIndex =
      countryCostIndices[country as keyof typeof countryCostIndices];
    const totalCost =
      baseCosts.housing[housing] * countryIndex +
      baseCosts.food[food] * countryIndex +
      baseCosts.transportation[transportation] * countryIndex +
      baseCosts.entertainment[entertainment] * countryIndex;
    setMonthlyCost(Math.round(totalCost));
  };

  return (
    <div className="w-full h-screen bg-unimaytLight">
      <SecondaryHeader dropdown search />

      <Card className="bg-unimaytLight px-0 lg:px-64">
        <CardHeader>
          <div className="relative flex items-center justify-center lg:mb-1">
            <div className="absolute left-0 right-0 top-1/2 h-px bg-white"></div>
            <h2 className="relative z-10 bg-unimaytLight px-2 text-3xl lg:text-6xl font-bold whitespace-nowrap text-unimayt">
              Cost of Living
            </h2>
          </div>
          <CardTitle className="text-center text-unimayt text-lg lg:text-2xl">
            Estimate Living Expenses with Unimayt's Study Abroad Cost
            Calculator.
          </CardTitle>
          <CardDescription className="text-center text-white text-sm">
            Use our cost of living calculator to estimate how much you will need
            to cover all your expenses as an international student.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form className="space-y-6 border-2 border-white rounded-xl p-8">
            <div className="space-y-2">
              <Label
                className="font-bold text-xl sm:text-lg lg:text-xl text-unimayt"
                htmlFor="country"
              >
                Study Destination:
              </Label>
              <Select
                onValueChange={(value) =>
                  setCountry(value as keyof typeof countryCostIndices)
                }
              >
                <SelectTrigger id="country">
                  <SelectValue placeholder="Select a country" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(countryCostIndices).map((country) => (
                    <SelectItem key={country} value={country}>
                      {country}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label className="font-bold text-xl sm:text-lg lg:text-xl text-unimayt">
                Housing:
              </Label>
              <RadioGroup
                defaultValue="shared"
                onValueChange={(value) =>
                  setHousing(value as "shared" | "nonShared")
                }
              >
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 text-white">
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="shared" id="shared" />
                    <Label htmlFor="shared">Shared Room</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="nonShared" id="nonShared" />
                    <Label htmlFor="nonShared">Non-shared Room</Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className="space-y-2">
              <Label className="font-bold text-xl sm:text-lg lg:text-xl text-unimayt">
                Food:
              </Label>
              <RadioGroup
                defaultValue="cookMostly"
                onValueChange={(value) =>
                  setFood(value as "cookMostly" | "eatOutOften")
                }
              >
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 text-white">
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="cookMostly" id="cookMostly" />
                    <Label htmlFor="cookMostly">Cook Mostly</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="eatOutOften" id="eatOutOften" />
                    <Label htmlFor="eatOutOften">Eat Out Often</Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className="space-y-2">
              <Label className="font-bold text-xl sm:text-lg lg:text-xl text-unimayt">
                Transportation:
              </Label>
              <RadioGroup
                defaultValue="publicTransport"
                onValueChange={(value) =>
                  setTransportation(value as "publicTransport" | "rideshare")
                }
              >
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 text-white">
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value="publicTransport"
                      id="publicTransport"
                    />
                    <Label htmlFor="publicTransport">Public Transport</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="rideshare" id="rideshare" />
                    <Label htmlFor="rideshare">Rideshare (e.g., Uber)</Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className="space-y-2">
              <Label className="font-bold text-xl sm:text-lg lg:text-xl text-unimayt">
                Entertainment:
              </Label>
              <RadioGroup
                defaultValue="movies"
                onValueChange={(value) =>
                  setEntertainment(value as "movies" | "clubbing")
                }
              >
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 text-white">
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="movies" id="movies" />
                    <Label htmlFor="movies">Movies</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="clubbing" id="clubbing" />
                    <Label htmlFor="clubbing">Clubbing</Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
          </form>
        </CardContent>
        <CardFooter className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-between space-y-4 lg:space-y-0 lg:space-x-8 lg:mt-2">
          <Button className="bg-unimayt lg:w-3/6" onClick={calculateCost}>
            Calculate Cost of Living
          </Button>
          {monthlyCost > 0 && (
            <div className="lg:text-lg flex flex-col lg:flex-row lg:gap-x-6 w-full lg:w-auto ">
              <p className="font-bold text-unimayt">
                Estimated Monthly Cost :{" "}
                <strong className="text-unimayt">
                  ${monthlyCost.toLocaleString()}
                </strong>
              </p>
              <p className="font-bold text-unimayt">
                Estimated Yearly Cost :{" "}
                <strong className="text-unimayt">
                  ${(monthlyCost * 12).toLocaleString()}
                </strong>
              </p>
            </div>
          )}
        </CardFooter>
      </Card>
      <Footer />
    </div>
  );
};

export default CostCalculator;

import { FaFacebook, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-unimayt text-white py-8 border-t flex justify-center items-center">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg">UNIMAYT</h4>
            <p className="text-sm">© 2024 Unimayt.</p>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg mb-4">What We Do</h4>
            <ul className="text-sm space-y-2">
              <li>
                <a href="#" className="hover:underline">
                  Student Services
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Student Recruitment
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Unimayt Abroad Network
                </a>
              </li>
              <li>
                <a href="/contact-us" className="hover:underline">
                  Support
                </a>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg mb-4">Site Map</h4>
            <ul className="text-sm space-y-2 hover:cursor-pointer">
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/ourImpact");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Our Impact
                  {/* </Link> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/dataEthics");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Data Ethics
                  {/* </Link> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/privacyPolicy");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Privacy Policy
                  {/* </Link> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/TermsAndConditions");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Terms and Conditions
                  {/* </Link> */}
                </div>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg mb-4">About Us</h4>
            <ul className="text-sm space-y-2">
              <li>
                <a href="mailto:info@unimayt.com">info@unimayt.com</a>
              </li>
              <li>
                <a>5A/4, NIT, FARIDABAD, HARYANA, INDIA</a>
              </li>
              <li className="pt-4">
                <button
                  className="bg-unimaytLight hover:border-1 text-white font-semibold py-2 px-4 rounded"
                  onClick={() =>
                    (window.location.href = "mailto:info@unimayt.com")
                  }
                >
                  Connect to Our Team
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-lg mb-4">Let's Stay Connected</h4>
            <ul className="text-sm space-x-4 flex flex-row">
              <li>
                <a
                  href="https://www.linkedin.com/company/unimayt/"
                  className=""
                >
                  <FaLinkedin size={25} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/unimayt_/"
                  className="hover:underline"
                >
                  <FaInstagramSquare size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

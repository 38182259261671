import { Route, Routes } from "react-router-dom";
import Landing from "./components/shared/Landing";
import DataEthics from "./components/layout/FooterComponents/DataEthics";
import TermsAndConditions from "./components/layout/FooterComponents/TermsAndConditions";
import Resources from "./pages/Resources";
import OurImpacts from "./components/layout/FooterComponents/OurImpacts";
import PrivacyPolicies from "./components/layout/FooterComponents/PrivacyPolicies";
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from "./scrollToTop";
import About from "./pages/About";
import Login from "./pages/auth/Login";
import Home from "./pages/Home";
import Team from "./components/shared/Team";
import Partnership from "./components/shared/Partnership";
import ContactTeam from "./pages/ContactTeam";
import CostCalculator from "./pages/CostCalculator";
import Footer from "./components/layout/Footer";
import Journey from "./pages/Journey";
import Friend from "./pages/Friend";

export default function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/Partnership" element={<Partnership />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/dataEthics" element={<DataEthics />} />
        <Route path="/ourImpact" element={<OurImpacts />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicies />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/costCalculator" element={<CostCalculator />} />
        <Route path="/contact-us" element={<ContactTeam />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="*" element={<Landing />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/friends/:guid"
          element={
            <ProtectedRoute>
              <Friend />
            </ProtectedRoute>
          }
        />

        <Route
          path="/journey"
          element={
            <ProtectedRoute>
              <Journey />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

import { FaFacebook, FaLinkedin, FaMailBulk } from "react-icons/fa";
import sidharthBhutaniTeam from "../../assets/sidharthBhutaniTeam.jpg";
import rishabhMahanaTeam from "../../assets/rishabhMahanaTeam.jpg";
import GursahibNegiTeam from "../../assets/GursahibNegiTeam.png";
import SanjanaVeerannaTeam from "../../assets/SanjanaVeerannaTeam.jpeg";

const Team = () => {
  return (
    <>
      <div className="bg-unimaytLight lg:h-screen flex justify-center items-center pt-12 lg:pt-0">
        <section>
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:pb-16 pt-4 lg:px-0">
            <div className="relative flex items-center justify-center mb-8 lg:mb-16">
              <div className="absolute left-0 right-0 top-1/2 h-px bg-white"></div>
              <h2 className="relative z-10 bg-unimaytLight px-4 text-4xl lg:text-6xl font-bold whitespace-nowrap text-unimayt">
                Meet Our Team
              </h2>
            </div>
            <div className="mx-auto mb-8 max-w-screen-sm">
              <p className="text-white sm:text-xl">
                We're committed to bridging the gap between students and
                universities. Get to know us more.
              </p>
            </div>
            <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div className="text-center text-white group transition-colors duration-300 p-4 rounded-lg transform hover:scale-105">
                <img
                  className="mx-auto mb-4 w-36 h-36 rounded-full transition-transform duration-500 transform group-hover:scale-110 border-2"
                  src={sidharthBhutaniTeam}
                  alt="Sidharth Bhutani Avatar"
                />
                <h3 className="mb-1 text-2xl font-bold tracking-tight transition-transform duration-500 transform group-hover:scale-105">
                  <a href="#">Sidharth Bhutani</a>
                </h3>
                <p className="transition-transform duration-500 transform group-hover:scale-105">
                  Founder and CEO of Unimayt, driving innovation in student
                  connections and university collaborations worldwide.
                </p>
                <ul className="flex justify-center mt-4 space-x-4">
                  <li></li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/sidharthbhutani/"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:sidharth@unimayt.com"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaMailBulk />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="text-center text-white group transition-colors duration-300 p-4 rounded-lg transform hover:scale-105">
                <img
                  className="mx-auto mb-4 w-36 h-36 rounded-full transition-transform duration-500 transform group-hover:scale-110 border-2"
                  src={rishabhMahanaTeam}
                  alt="Rishab Mahana Avatar"
                />
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white transition-transform duration-500 transform group-hover:scale-105">
                  <a href="#">Rishabh Mahana</a>
                </h3>
                <p className="transition-transform duration-500 transform group-hover:scale-105">
                  COO, Product Manager and Co-Founder at Unimayt, leading
                  platform development and ensuring seamless student-university
                  engagement.
                </p>
                <ul className="flex justify-center mt-4 space-x-4">
                  <li></li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/rishabh-mahana/"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:Mahanarishabh@gmail.com"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaMailBulk />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="text-center text-white group transition-colors duration-300 p-4 rounded-lg transform hover:scale-105">
                <img
                  className="mx-auto mb-4 w-36 h-36 rounded-full transition-transform duration-500 transform group-hover:scale-110 border-2"
                  src={GursahibNegiTeam}
                  alt="Gursahib Nagi Avatar"
                />
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white transition-transform duration-500 transform group-hover:scale-105">
                  <a href="#">Gursahib Nagi</a>
                </h3>
                <p className="transition-transform duration-500 transform group-hover:scale-105">
                  UI/UX Designer and Co-Founder at Unimayt, crafting intuitive
                  and user-friendly experiences for students and universities.
                </p>
                <ul className="flex justify-center mt-4 space-x-4">
                  <li></li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/gursahib-nagi-64436811a/"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:Gursahib.nagi150@gmail.com"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaMailBulk />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="text-center text-white group transition-colors duration-300 p-4 rounded-lg transform hover:scale-105">
                <img
                  className="mx-auto mb-4 w-36 h-36 rounded-full transition-transform duration-500 transform group-hover:scale-110 border-2"
                  src={SanjanaVeerannaTeam}
                  alt="Sanjana Veeranna"
                />
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white transition-transform duration-500 transform group-hover:scale-105">
                  <a href="#">Sanjana Veeranna</a>
                </h3>
                <p className="transition-transform duration-500 transform group-hover:scale-105">
                  Full stack Developer at Unimayt, building responsive and
                  dynamic interfaces for a seamless user experience.
                </p>
                <ul className="flex justify-center mt-4 space-x-4">
                  <li></li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/sanjana-veeranna/"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:sanjanaveeranna14@gmail.com"
                      className="text-white hover:text-unimayt transition-colors duration-300"
                    >
                      <FaMailBulk />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Team;

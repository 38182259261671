import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { z } from "zod";
import googlelogo from "../../assets/googlelogo.svg";
import applelogo from "../../assets/applelogo.svg";
import { useState } from "react";
import { signupSchema } from "../../zodSchema/signupSchema";
import UseAuth from "../../services/useAuth";

type SignupSchema = z.infer<typeof signupSchema>;

const SignupModal = () => {
  const navigate = useNavigate();
  const { registerUser } = UseAuth();
  const [isChecked, setIsChecked] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  });

  const onSubmit = (data: SignupSchema) => {
    if (!isChecked) {
      toast.error("Agree to the given terms and conditions.");
      return;
    }
    const obj = {
      password: data.password,
      first_name: data.first_name,
      email: data.email,
      gender: data.gender,
    };
    signupMutation.mutate(obj);
  };

  const signupMutation = useMutation({
    mutationFn: registerUser,
    onSuccess: async (data) => {
      localStorage.setItem("userInfo", JSON.stringify(data));
      toast.success("Signup Successful");
      navigate("/home");
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 409) {
        toast.error("User already exists, sign up with another email.");
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    },
  });

  const handleGoogleSignup = async () => {
    console.error("Google signup not implemented yet.");
  };

  const handleAppleSignup = async () => {
    console.error("Apple signup not implemented yet.");
  };

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="text-center md:pt-8">
        <h2 className="text-2xl font-bold text-white">Create an Account</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="md:space-y-0">
        <div className="flex justify-center ">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Full Name
            </label>
            <input
              type="text"
              className="w-full mt-1 border p-2 rounded-lg"
              {...register("first_name")}
            />
            {errors.first_name && (
              <p className="text-red-500 text-xs text-center">
                {errors.first_name.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Email
            </label>
            <input
              type="email"
              className="w-full mt-1 border p-2 rounded-lg"
              {...register("email")}
            />
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email.message}</p>
            )}
          </div>
        </div>

        {/* <div className="flex justify-center">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Username
            </label>
            <input
              type="text"
              className="w-full mt-1 border p-2 rounded-lg"
              {...register("username")}
            />
            {errors.username && (
              <p className="text-red-500 text-xs">{errors.username.message}</p>
            )}
          </div>
        </div> */}

        {/* Gender selection */}
        <div className="flex justify-center">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Gender
            </label>
            <div className="flex items-center mt-1 space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="Male"
                  {...register("gender")}
                  className="form-radio text-Button"
                />
                <span className="ml-2 text-white">Male</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="Female"
                  {...register("gender")}
                  className="form-radio text-Button"
                />
                <span className="ml-2 text-white">Female</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="Prefer not to say"
                  {...register("gender")}
                  className="form-radio text-Button"
                />
                <span className="ml-2 text-white">Prefer not to say</span>
              </label>
            </div>
            {errors.gender && (
              <p className="text-red-500 text-xs">{errors.gender.message}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Enter Password
            </label>
            <input
              type="password"
              className="w-full mt-1 border p-2 rounded-lg"
              {...register("password")}
            />
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password.message}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Confirm Password
            </label>
            <input
              type="password"
              className="w-full mt-1 border p-2 rounded-lg"
              {...register("confirmPassword")}
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-xs">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center text-[11px]">
          <div
            onClick={handleCheckboxClick}
            className={`w-6 lg:w-4 h-4 border border-unimayt ml-4 mr-2 flex justify-center items-center cursor-pointer ${isChecked ? "bg-Button" : "bg-white"}`}
          >
            {isChecked && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-[#FFFFFF] font-bold"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
          </div>
          By clicking Agree & Join or Continue, you agree to the Unimayt User
          Agreement, Privacy Policy, and Cookie Policy.
        </div>

        <div className="flex justify-center">
          <div className="w-11/12">
            <button
              type="submit"
              className="w-full py-2 flex items-center justify-center border border-gray-600 rounded-lg bg-Button text-[#E7E7E7] mt-4"
            >
              Submit
            </button>
          </div>
        </div>

        <div className="mt-4 text-center">
          <p className="text-sm font-semibold text-white flex items-center">
            <span className="w-11/12 border-b border-solid border-white/50"></span>
            <span className="px-2 text-sm pb-2 text-[#E7E7E7]">or</span>
            <span className="w-11/12 border-b border-solid border-white/50"></span>
          </p>
        </div>

        <div className="flex justify-center mt-4">
          <div className="w-11/12 flex flex-row items-center space-x-2">
            <button
              onClick={handleGoogleSignup}
              className="w-full h-12 flex items-center justify-center p-2 rounded-lg bg-Button text-white"
            >
              <img
                src={googlelogo}
                alt="Google logo"
                className="w-4 h-3 mr-2"
              />
              <span className="text-[#BDBFBE] font-semibold text-center text-sm leading-[22px]">
                Sign up with Gmail
              </span>
            </button>
            <button
              onClick={handleAppleSignup}
              className="w-full h-12 flex items-center justify-center p-2 rounded-lg bg-Button text-white"
            >
              <img src={applelogo} alt="Apple logo" className="w-5 h-5" />
              <span className="text-[#BDBFBE] font-semibold text-center text-sm leading-[22px]">
                Sign up with Apple
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SignupModal;

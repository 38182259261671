import React from "react";
import getAuthState from "../hooks/getAuthState";
import OtherProfile from "../components/shared/profile/OtherProfile";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import MessagingWindow from "../components/shared/chat/MessagingWindow";
import { MdClose } from "react-icons/md";
import { PiChatsBold } from "react-icons/pi";
import { useUserStore } from "../zustandStore/useUserStore";
import { getStoredConversation } from "../lib/conversation";

const Friend: React.FC = () => {
  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const chatRoomOpen = useUserStore((state) => state.chatRoomOpen);

  if (!getAuthState()) return <div>Loading...</div>;

  return (
    <>
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search editProfile />
      </div>

      <div className="flex flex-col min-h-screen bg-[#f1f5fb]">
        <div className="flex gap-x-2 sm:px-4 lg:px-2 py-2 md:h-screen h-screen">
          <div className="md:!w-9/12 w-full overflow-y-scroll">
            <OtherProfile />
          </div>

          <div className="w-3/12 hidden md:block lg:block">
            <MessagingWindow conversation={getStoredConversation()} />
          </div>

          <div
            className={`${
              chatRoomOpen
                ? "fixed inset-0 z-50 w-full h-full bg-white"
                : "hidden"
            } lg:hidden`}
          >
            <div className="relative h-full p-1">
              <MessagingWindow conversation={getStoredConversation()} />
              <button
                className="absolute top-6 right-4 text-xl text-white font-extrabold"
                onClick={() => setChatRoomOpen(null)}
              >
                <MdClose />
              </button>
            </div>
          </div>
        </div>

        {!chatRoomOpen && (
          <button
            className="fixed bottom-6 right-3 lg:hidden bg-Gray text-unimayt p-3 rounded-full shadow-lg flex flex-row gap-x-2"
            onClick={() => setChatRoomOpen(getStoredConversation()[0])}
          >
            <PiChatsBold size={25} />
          </button>
        )}
      </div>
    </>
  );
};
export default Friend;

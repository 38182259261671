import React, { useState } from "react";
import Footer from "../layout/Footer";

const MiniJourney: React.FC = () => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  const handleToggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleCheckboxChange = (index: number, subIndex: number) => {
    setCheckedItems((prev) => ({
      ...prev,
      [`${index}-${subIndex}`]: !prev[`${index}-${subIndex}`],
    }));
  };

  return (
    <div className="-mt-8 bg-Gray py-0">
      <div className="relative space-y-8 ml-4">
        <div className="absolute inset-0 ml-5 -translate-x-px md:ml-4 md:-translate-x-0 h-full w-0.5 bg-gray-600"></div>

        {[...Array(11)].map((_, index) => (
          <div className="relative" key={index}>
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center space-x-4">
                {/* Icon */}
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow">
                  <svg
                    style={{ fill: index === 0 ? "#2F4848" : "#789491" }} // Use hex codes directly
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                  >
                    <path d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
                  </svg>
                </div>

                {/* Title Text */}
                <div className="text-slate-500 flex items-center">
                  <span className="text-Button font-bold text-sm italic">
                    {
                      [
                        "SHORTLIST COURSES & COLLEGES",
                        "TESTS",
                        "DOCUMENT PREPARATIONS",
                        "APPLICATION",
                        "MAYTING",
                        "FINANCES",
                        "ACCOMODATIONS",
                        "VISA",
                        "AIRTICKET",
                        "CURRENCY",
                        "DEPARTURE",
                      ][index]
                    }
                  </span>
                  <button
                    className="ml-2 text-Button focus:outline-none"
                    onClick={() => handleToggleDropdown(index)}
                  >
                    <svg
                      className={`w-4 h-4 transition-transform ${openDropdown === index ? "rotate-180" : ""} fill-Button`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                    >
                      <path d="M5 6l4 4 4-4H5z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* Dropdown Card */}
            {openDropdown === index && (
              <div className="pl-2 text-unimayt ml-14 md:ml-44">
                {/* Subtopics here */}
                <div className="relative">
                  {[
                    "Review candidate resumes",
                    "Establish the criteria",
                    "Create a candidate shortlisting",
                  ].map((subtopic, subIndex) => (
                    <div
                      className="flex items-center space-x-4 mb-2"
                      key={subIndex}
                    >
                      <span className="text-slate-500 font-bold">
                        {subtopic}
                      </span>
                      <input
                        type="checkbox"
                        checked={!!checkedItems[`${index}-${subIndex}`]}
                        onChange={() => handleCheckboxChange(index, subIndex)}
                        className="w-4 h-4 accent-Button"
                        style={{
                          accentColor: checkedItems[`${index}-${subIndex}`]
                            ? "Button"
                            : "Button", // Use hex code for gray-300
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="w-full mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default MiniJourney;

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt, FaChevronDown } from "react-icons/fa";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UseUsers from "../../../services/useUsers";
import { UpdateSchema } from "../../../zodSchema/UpdateSchema";
import moment from "moment";

type profileUpdateSchema = z.infer<typeof UpdateSchema>;

interface EditProfileModalProps {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  profile?: profileUpdateSchema | null;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  isEditing,
  setIsEditing,
  profile,
}) => {
  const { updateProfile } = UseUsers();
  const queryClient = useQueryClient();

  const [selectedOption, setSelectedOption] = useState<string | null>(
    profile?.university_name ? "I have an acceptance letter" : null
  );
  const [universityName, setUniversityName] = useState<string>(
    profile?.university_name || ""
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [status, setStatus] = useState<string | null>("Status");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<profileUpdateSchema>({
    resolver: zodResolver(UpdateSchema),
  });

  React.useEffect(() => {
    if (profile) {
      setValue("campus_location", profile.campus_location || "");
      setValue("course_name", profile.course_name || "");
      setValue("course_start_date", profile.course_start_date || null);
      setValue("city_name", profile.city_name || "");
    }
  }, [profile, setValue]);

  const onSubmit: SubmitHandler<profileUpdateSchema> = async (data) => {
    const obj = {
      university_name:
        selectedOption === "I am currently shortlisting universities"
          ? "No Acceptance Letter"
          : universityName,
      campus_location: data.campus_location || "",
      course_name: data.course_name || "",
      course_start_date:
        selectedOption === "I am currently shortlisting universities"
          ? null
          : moment(data.course_start_date).format("YYYY-MM-DD") || "",
      city_name: data.city_name || "",
    };
    updateMutation.mutate(obj);
  };

  const updateMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Update Successful");
      setIsEditing(false);
    },
    onError: () => {
      toast.error("Update failed");
    },
  });

  const handleDropdownSelect = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    if (
      option === "I have an acceptance letter" ||
      option === "I have multiple acceptance letters"
    ) {
      setStatus("Acceptance Letter Available");
    } else {
      setStatus(null);
    }
  };

  if (!isEditing) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50 backdrop-blur-sm">
      <div className="relative bg-unimaytLight p-8 rounded-lg w-full max-w-lg md:w-100 md:h-auto h-full flex justify-center items-center flex-col lg:pt-12 lg:-mb-4">
        <button
          onClick={() => setIsEditing(false)}
          className="absolute top-2 right-2 lg:right-4 text-white text-xl font-bold"
        >
          &times;
        </button>

        <h2 className="mb-4 text-center text-2xl font-bold text-white">
          Edit Profile
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-1">
          <label className="block text-sm font-semibold text-white relative">
            University Name {status && <span>({status})</span>}
            <div className="relative">
              <input
                type="text"
                className={`w-full mt-1 border p-2 rounded-lg bg-white ${
                  selectedOption === "I am currently shortlisting universities"
                    ? "text-gray-500"
                    : "text-black"
                }`}
                placeholder={
                  selectedOption === "I am currently shortlisting universities"
                    ? "No Acceptance Letter"
                    : profile?.university_name || "Enter your university name"
                }
                value={universityName}
                onChange={(e) => setUniversityName(e.target.value)}
                disabled={
                  selectedOption === "I am currently shortlisting universities"
                }
              />

              <FaChevronDown
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="absolute right-3 top-4 text-gray-600 cursor-pointer"
              />
            </div>
            {isDropdownOpen && (
              <ul className="absolute bg-white border rounded-lg shadow-lg w-full mt-2 z-10 text-black">
                <li
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() =>
                    handleDropdownSelect("I have an acceptance letter")
                  }
                >
                  I have an acceptance letter
                </li>
                <li
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() =>
                    handleDropdownSelect(
                      "I am currently shortlisting universities"
                    )
                  }
                >
                  I am currently shortlisting universities
                </li>
                <li
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() =>
                    handleDropdownSelect("I have multiple acceptance letters")
                  }
                >
                  I have multiple acceptance letters
                </li>
              </ul>
            )}
            {errors.university_name && (
              <p className="text-red-500">{errors.university_name.message}</p>
            )}
          </label>

          <label className="block text-sm font-semibold text-white">
            Campus location
            <input
              type="text"
              {...register("campus_location")}
              className="w-full mt-1 border p-2 rounded-lg text-black"
              placeholder={
                profile?.campus_location || "Please type in your campus city"
              }
            />
            {errors.campus_location && (
              <p className="text-red-500">{errors.campus_location.message}</p>
            )}
          </label>

          <label className="block text-sm font-semibold text-white">
            Which course are you enrolled into
            <input
              type="text"
              {...register("course_name")}
              className="w-full mt-1 border p-2 rounded-lg text-black"
              placeholder={profile?.course_name || "Enter your course name"}
            />
            {errors.course_name && (
              <p className="text-red-500">{errors.course_name.message}</p>
            )}
          </label>

          <label className="block text-sm font-semibold text-white">
            When does the course start
            <Controller
              control={control}
              name="course_start_date"
              render={({ field }) => (
                <div className="relative ">
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    className="w-full mt-1 border p-2 rounded-lg text-black"
                    placeholderText="Select a start date"
                    disabled={
                      selectedOption ===
                      "I am currently shortlisting universities"
                    }
                    dateFormat="yyyy/MM/dd"
                    isClearable
                  />
                  <FaCalendarAlt className="absolute right-3 top-3 text-gray-600 cursor-pointer" />
                </div>
              )}
            />
            {errors.course_start_date && (
              <p className="text-red-500">
                {errors.course_start_date?.message}
              </p>
            )}
          </label>

          <label className="block text-sm font-semibold text-white pb-4">
            Home Location
            <input
              type="text"
              {...register("city_name")}
              className="w-full mt-1 border p-2 rounded-lg text-black"
              placeholder={profile?.city_name || "City you call home"}
            />
            {errors.city_name && (
              <p className="text-red-500">{errors.city_name.message}</p>
            )}
          </label>
          <input
            type="submit"
            className="w-full py-2 flex items-center justify-center border border-gray-600 rounded-lg bg-Button text-[#E7E7E7]"
          />
        </form>
      </div>
    </div>
  );
};

export default EditProfileModal;

import { useState } from "react";
import { FaFacebook, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import Footer from "../components/layout/Footer";

const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendMessageOnWhatsApp = () => {
    const { firstName, lastName, phone, message } = formData;
    const fullName = `${firstName} ${lastName}`;
    const whatsappMessage = `Name: ${fullName}\nPhone: ${phone}\nMessage: ${message}`;

    const whatsappLink = `https://wa.me/918077268203?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <SecondaryHeader dropdown />
      <div className="flex-grow flex justify-center items-center w-full bg-unimaytLight">
        <div className="container my-4 px-4 lg:px-10">
          <div className="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl bg-white">
            <div className="flex">
              <h1 className="font-bold text-3xl text-unimayt">
                Send Us a Message
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
              <input
                name="firstName"
                className="w-full bg-unimaytLight text-black mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline placeholder-white placeholder-opacity-100"
                type="text"
                placeholder="First Name*"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <input
                name="lastName"
                className="w-full bg-unimaytLight text-black mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline placeholder-white placeholder-opacity-100"
                type="text"
                placeholder="Last Name*"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <input
                name="email"
                className="w-full bg-unimaytLight text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline placeholder-white placeholder-opacity-100"
                type="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleInputChange}
              />
              <input
                name="phone"
                className="w-full bg-unimaytLight text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline placeholder-white placeholder-opacity-100"
                type="number"
                placeholder="Phone*"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="my-4">
              <textarea
                name="message"
                placeholder="Message*"
                className="w-full h-32 bg-unimaytLight text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline placeholder-white placeholder-opacity-100"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="my-2 w-1/2 lg:w-1/4">
              <button
                onClick={sendMessageOnWhatsApp}
                className="uppercase text-sm font-bold tracking-wide bg-unimayt text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline"
              >
                Send Message
              </button>
            </div>
          </div>

          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-unimayt rounded-2xl mb-16">
            <div className="flex flex-col text-white">
              <h1 className="font-bold uppercase text-3xl mb-4">
                CONTACT US HERE...
              </h1>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-xl">UNIMAYT</h2>
                  <p className="text-gray-400">
                    5A/4, NIT, FARIDABAD, HARYANA, INDIA
                  </p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-xl">Call Us</h2>
                  <ul className="text-gray-400">
                    Mail:
                    <a href="mailto:info@unimayt.com">info@unimayt.com</a>
                  </ul>
                  <p className="text-gray-400">Tel: xxx-xxx-xxx</p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2 ml-2 gap-x-4">
                <a href="https://www.linkedin.com/company/unimayt/">
                  <FaLinkedin size={25} />
                </a>
                <a
                  href="https://www.instagram.com/unimayt_/"
                  className="hover:underline"
                >
                  <FaInstagramSquare size={25} />
                </a>
                <a
                  href="https://wa.me/8077268203"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  <i
                    className="fab fa-whatsapp"
                    style={{ fontSize: "25px" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:mt-[-50px]">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;

import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UseUsers from "../../../services/useUsers";
import { MdEdit, MdMessage } from "react-icons/md";
import EditProfileModal from "./EditProfileModel";
import EditBioModal from "./EditBioModel";
import { useNavigate } from "react-router-dom";
import getAuthState from "../../../hooks/getAuthState";
import toast from "react-hot-toast";
import { User, UserProfileProps } from "../../../types/UserProfile";
import { useUserStore } from "../../../zustandStore/useUserStore";
import UseChat from "../../../services/useChat";
import moment from "moment";
import BannerImage from "../../../assets/BannerImage.jpg";
import ReadMore from "../../../lib/ReadMore";
import Footer from "../../layout/Footer";

const SkeletonLoader = () => (
  <div className="w-full h-screen">
    <div className="animate-pulse flex flex-col space-y-6 h-full md:w-[70dvw] w-screen">
      <div className="relative w-full h-48 bg-gray-400/70 bg-cover bg-center">
        <div className="absolute -bottom-12 left-2 md:left-4 md:-bottom-28">
          <div className="md:w-40 md:h-40 w-28 h-28 bg-gray-400/70 rounded-full border-4 border-white"></div>
        </div>
      </div>

      <div className="flex items-center space-x-4 md:mx-48 mx-4 gap-x-2 pb-8">
        <div className="flex-1 space-y-4">
          <div className="h-4 bg-gray-400/70 rounded md:w-40 w-28 mx-28 md:mx-0"></div>
          <div className="space-y-2">
            <div className="h-3 bg-gray-400/70 rounded w-64"></div>
            <div className="h-3 bg-gray-400/70 rounded w-64"></div>
            <div className="h-3 bg-gray-400/70 rounded w-64"></div>
          </div>
        </div>
      </div>

      <div className="mb-4 bg-gray-400/70 p-2 rounded relative h-64"></div>
    </div>
  </div>
);
const MyProfile: React.FC<UserProfileProps> = ({
  isLoading,
  // setConversation,
  // conversation,
}) => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editableBio, setEditableBio] = useState<string>(
    getAuthState().bio || ""
  );
  const [isEditingBio, setIsEditingBio] = useState<boolean>(false);

  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);

  const users = useUserStore((state) => state.users);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const { getUsersbyId, uploadProfileImage } = UseUsers();
  const { postStartChat } = UseChat();
  const queryClient = useQueryClient();

  const { data: user, isError } = useQuery({
    queryKey: ["user", getAuthState().guid],
    queryFn: getUsersbyId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const profileImageUploadMutation = useMutation({
    mutationFn: uploadProfileImage,
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Update Successful");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // const newConversationStart = (data: any) => {
  //   const newConversation = data.data;
  //   setConversation((prevConversation: any) => [
  //     newConversation,
  //     ...prevConversation,
  //   ]);
  // };

  const StartChatMutation = useMutation({
    mutationFn: postStartChat,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      setChatRoomOpen(data.data);
      setSelectedUser(data.data);
    },
  });

  const OnProfileImageSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append("upload_image", file);
      profileImageUploadMutation.mutate(formData);
    }
  };

  const handleBioSave = (updatedBio: string) => {
    setEditableBio(updatedBio);
    setIsEditingBio(false);
  };

  return (
    <div className="w-full">
      {isLoading ? (
        <SkeletonLoader />
      ) : isError ? (
        // <p className="text-red-600">{error.message}</p>
        <SkeletonLoader />
      ) : (
        <>
          <div
            className="relative w-full h-48 bg-cover bg-center hover:opacity-80 transition-opacity duration-300"
            style={{
              backgroundImage: `url(${user?.user?.background_image || BannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: "unimayt",
            }}
          >
            <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 hover:text-white transition-opacity duration-300">
              <MdEdit className="text-white text-3xl" />
            </div>
          </div>

          <div className="flex items-center md:mx-4 mx-3 gap-x-2 relative">
            <div className="relative group">
              <div className="md:w-44 md:h-44 w-28 h-28 bg-unimayt rounded-full relative top-1/2 transform -translate-y-1/2 lg:top-1/3 lg:-translate-y-1/3">
                {user?.user?.user_image && (
                  <img
                    src={user?.user?.user_image}
                    alt="Profile"
                    className="w-full h-full rounded-full object-fill aspect-auto border-2 border-unimayt"
                  />
                )}
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <label htmlFor="file-upload" className="cursor-pointer">
                    <MdEdit className="text-white text-3xl" />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    accept=".jpg, .png"
                    onChange={(event) => {
                      OnProfileImageSelect(event);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full md:ml-4">
              <div className="flex justify-between items-center md:-mt-14 -mt-12">
                <h2 className="md:text-2xl text-lg font-bold">
                  {user?.user?.first_name || "Loading..."}
                </h2>
                <div
                  onClick={() => setIsEditing(true)}
                  className="flex items-center border border-unimayt md:gap-2 rounded p-1 font-semibold cursor-pointer justify-center gap-x-1 hover:scale-105 duration-150 ease-in-out transition-all"
                >
                  <MdEdit className="text-unimayt" />
                  <div className="hidden sm:block w-[1px] h-5 bg-unimayt"></div>
                  <button className="hidden sm:block text-black leading-5 px-1">
                    Edit Profile
                  </button>
                </div>
              </div>

              <div className="hidden md:block font-bold text-sm md:text-sm lg:mt-1">
                <div>
                  <span className="font-semibold">
                    {`${user?.user?.university_name || "Enter your University..."} (${user?.user?.campus_location || "Location..."})`}
                  </span>
                </div>
                <div>
                  <span className="font-semibold">
                    {`${user?.user?.course_name || "Enter your Course Enrollement..."} (${moment(user?.user?.course_start_date).format("YYYY-MMM") || "Start Date..."})`}
                  </span>
                </div>
                <div>
                  <span className="font-bold">Home Location:</span>{" "}
                  <span className="font-semibold">
                    {user?.user?.city_name || "..."}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="block md:hidden text-md -mt-12 ml-4 mb-3 md:mb-0 md:ml-0 font-bold text-sm md:text-sm lg:mt-1">
            <div>
              <span className="font-semibold">
                {`${user?.user?.university_name || "Enter your University..."} (${user?.user?.campus_location || "Location..."})`}
              </span>
            </div>
            <div>
              <span className="font-semibold">
                {`${user?.user?.course_name || "Enter your Course Enrollement..."} (${user?.user?.course_start_date || "Start Date..."})`}
              </span>
            </div>
            <div>
              <span className="font-bold">Home Location:</span>{" "}
              <span className="font-semibold">
                {user?.user?.city_name || "..."}
              </span>
            </div>
          </div>

          <div className="border-t border-gray-400 lg:-mt-12"></div>

          <div className="flex flex-col px-3 items-start bg-Gray py-2 my-2 rounded-lg">
            <div className="w-full flex justify-between items-center ">
              <div className="text-md font-bold">Journey so Far</div>
              <div
                onClick={() => {
                  setIsEditingBio(true);
                }}
                className="flex z-10 items-center border border-unimayt bg-white md:gap-2 rounded p-1 font-semibold cursor-pointer justify-center gap-x-1 hover:scale-105 duration-150 ease-in-out transition-all"
              >
                <MdEdit className="text-unimayt" />
                <div className="hidden sm:block w-[1px] h-5 bg-unimayt"></div>
                <div className="hidden sm:block text-black leading-5 px-1">
                  Edit About
                </div>
              </div>
            </div>
            <section className="mt-1">
              <div className="text-md font-normal max-h-24">
                {editableBio ? (
                  <ReadMore id={""} amountOfWords={28} text={editableBio} />
                ) : (
                  <div className="text-unimayt text-sm text-wrap text-ellipsis line-clamp-1 italic">
                    Share your Milestones, Dreams, and Aspirations...
                  </div>
                )}
              </div>
            </section>
          </div>

          <EditBioModal
            isOpen={isEditingBio}
            onClose={() => setIsEditingBio(false)}
            onSave={handleBioSave}
            bio={editableBio}
          />

          <div className="relative flex flex-col items-center justify-center mb-2 lg:mb-4 lg:mt-6">
            <div className="relative  px-4 text-sm lg:text-xl font-bold text-unimayt text-center">
              Your study abroad network: Students to meet before you start your
              Journey
            </div>
          </div>

          <div className="flex flex-wrap justify-center gap-1 ">
            {isLoading ? (
              <p className="text-gray-600">Loading users...</p>
            ) : users && users?.length > 0 ? (
              users?.map((recipient_user: User) => (
                <div
                  key={recipient_user.guid}
                  className="w-[80%] border lg:w-[35rem] xl:w-[20rem] justify-center h-15 m-1 p-1 rounded-lg flex items-center cursor-pointer hover:scale-105 duration-200 ease-in-out transition-all"
                  onClick={() => {
                    navigate(`/friends/${recipient_user.guid}`, {
                      state: { chat_guid: recipient_user.chat_guid },
                    });
                  }}
                >
                  <div className="w-8 h-8 bg-unimayt rounded-full mr-2 overflow-hidden">
                    {recipient_user.user_image && (
                      <img
                        src={recipient_user.user_image}
                        alt="Profile"
                        className="w-full h-full rounded-full object-cover"
                      />
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="text-sm font-semibold">
                      {recipient_user.username}
                    </div>
                    <div className="text-xs text-gray-900">
                      {user?.universities?.length >= 1 ? (
                        <ul>
                          {user.universities[0].name.length > 35
                            ? `${user.universities[0].name.substring(0, 28)}...`
                            : user.universities[0].name}
                        </ul>
                      ) : (
                        <div className="text-xs text-gray-500">
                          Not yet applied for university
                        </div>
                      )}

                      {user?.courses?.length >= 1 ? (
                        <ul>
                          {user.courses[0].name.length > 28
                            ? `${user.courses[0].name.substring(0, 28)}...`
                            : user.courses[0].name}
                        </ul>
                      ) : (
                        <div className="text-xs text-gray-500">
                          No course enrolled
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="ml-auto flex items-center">
                    <MdMessage
                      className="text-unimayt ml-4 mr-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (recipient_user.chat_guid == null) {
                          StartChatMutation.mutate(recipient_user.guid);
                        } else {
                          setSelectedUser(recipient_user);
                        }
                      }}
                      size={24}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p className="text-unimayt">Oops! Something went Wrong...</p>
            )}
          </div>
          <EditProfileModal isEditing={isEditing} setIsEditing={setIsEditing} />
        </>
      )}
      <div className="w-full mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default MyProfile;

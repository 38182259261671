import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { z } from "zod";
import googlelogo from "../../assets/googlelogo.svg";
import applelogo from "../../assets/applelogo.svg";
import login_crousal from "../../assets/login-crousal.jpg";
import SignupModal from "./SignupModal";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { loginSchema } from "../../zodSchema/LoginSchema";
import UseAuth from "../../services/useAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import About from "../About";
import Team from "../../components/shared/Team";
import Footer from "../../components/layout/Footer";
import Partnership from "../../components/shared/Partnership";
import SecondaryHeader from "../../components/layout/header/SecondaryHeader";

type LoginSchemaType = z.infer<typeof loginSchema>;

const Login = () => {
  const navigate = useNavigate();
  const { loginUser } = UseAuth();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = (data: { username: string; password: string }) => {
    loginMutation.mutate(data);
  };

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      localStorage.setItem("userInfo", JSON.stringify(data));
      toast.success("Login Successful");
      navigate("/home");
    },
    onError: (err: any) => {
      if (err.response?.status === 429) {
        toast.error("Too many requests. Please try again later.");
      } else if (err.response?.status === 401) {
        setError("password", {
          type: "manual",
          message: "Incorrect password",
        });
      } else if (err.response?.status === 404) {
        setError("username", {
          type: "manual",
          message: "Email does not exist",
        });
      } else {
        toast.error("An error occurred. Please try again.");
      }
    },
  });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(tokenResponse),
    onError: (error) => console.log(error),
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <SecondaryHeader />
      <section id="login-section">
        <div className="h-screen w-full bg-unimayt">
          <div className="w-full flex flex-col md:flex-row overflow-hidden h-full p-4 md:p-10 ">
            <div className="relative w-full md:w-1/2 h-full flex justify-center items-center flex-col gap-y-2">
              <div className="-mt-6 lg:mt-10 md:text-left -ml-16 md:-ml-0 mb-4 md:-mb-0">
                <h1
                  className="text-[30px] md:text-4xl lg:text-4xl font-bold text-[#D8D9DB]"
                  style={{ whiteSpace: "nowrap" }}
                >
                  The World Awaits
                </h1>
                <p className="text-sm md:text-lg font-semibold text-[#D8D9DB]">
                  Connect to Universities and Peers Globally
                </p>
              </div>
              <div className="flex flex-col gap-y-2 xl:gap-y-4 lg:pl-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <div className="space-y-3">
                    <label className="block text-base font-semibold text-white">
                      Email
                    </label>
                    <div className="flex items-center border border-gray-300 p-2 md:p-3 rounded-lg mt-1 bg-[#D8D9DB]">
                      <HiOutlineAtSymbol className="w-5 h-5 text-gray-400" />
                      <input
                        type="text"
                        placeholder="Enter email address"
                        className="w-full ml-2 outline-none bg-transparent placeholder:text-[#5A5A5A] placeholder:text-xs md:placeholder:text-sm placeholder:font-poppins placeholder:font-semibold placeholder:leading-5"
                        required
                        {...register("username")}
                      />
                      {errors.username && (
                        <p className="text-red-400 text-xs font-light">
                          {errors.username.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="block text-base font-semibold text-white">
                      Password
                    </label>
                    <div className="flex items-center border border-gray-300 p-2 md:p-3 rounded-lg mt-1 bg-[#D8D9DB]">
                      <FaLock className="w-4 h-4 md:h-5 text-gray-400" />
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        className="w-full ml-2 outline-none bg-transparent placeholder:text-[#5A5A5A] placeholder:text-xs md:placeholder:text-sm placeholder:font-poppins placeholder:font-semibold placeholder:leading-5"
                        required
                        {...register("password")}
                      />
                      <div
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer"
                      >
                        {showPassword ? (
                          <FaEyeSlash className="w-4 h-4 md:h-5 text-gray-400" />
                        ) : (
                          <FaEye className="w-4 h-4 md:h-5 text-gray-400" />
                        )}
                      </div>
                    </div>
                    {errors.password && (
                      <p className="text-red-400 text-xs font-light">
                        {errors.password.message}
                      </p>
                    )}
                    <button className="text-white text-xs md:text-sm pl-56 md:pl-64 hover:text-unimayt hover:font-semibold">
                      Forgot Password?
                    </button>
                  </div>

                  <button
                    type="submit"
                    className="w-full flex items-center justify-center py-2 border-gray-600 rounded-lg bg-Button text-[#E7E7E7] "
                  >
                    Login
                  </button>
                </form>
                <div className="w-full flex items-center justify-center gap-x-2">
                  <hr className="bg-white w-full " />
                  <div className="text-xs md:text-sm text-[#E7E7E7]">or</div>
                  <hr className="bg-white w-full" />
                </div>
                <div className="mt-2 flex flex-row space-x-2 md:space-x-4">
                  <div
                    className="flex flex-row items-center justify-center cursor-pointer w-full bg-Button text-[#BDBFBE] rounded-lg px-4 py-2 md:py-3"
                    onClick={() => handleGoogleLogin()}
                  >
                    <img
                      src={googlelogo}
                      alt="Google logo"
                      className="w-4 h-3 md:h-4 mr-2"
                    />
                    <div className="text-sm md:text-base">Login with Gmail</div>
                  </div>

                  <div className="flex flex-row items-center justify-center cursor-pointer w-full bg-Button text-[#BDBFBE] rounded-lg px-4 py-2 md:py-3">
                    <img
                      src={applelogo}
                      alt="Apple logo"
                      className="w-4 h-3 md:h-4 mr-2"
                    />
                    <div className="text-sm md:text-base whitespace-nowrap">
                      Login with Apple
                    </div>
                  </div>
                </div>

                <div className="text-xs md:text-sm text-poppins text-[#E7E7E7] w-full flex items-center justify-center">
                  Don't have an account?
                </div>
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="w-full flex items-center justify-center py-2 border-gray-600 rounded-lg bg-Button text-[#E7E7E7]">
                      Sign up
                    </button>
                  </DialogTrigger>
                  <DialogContent className="bg-unimaytLight border-none min-w-96 md:min-w-96 rounded-3xl p-6">
                    <SignupModal />
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            <div className="hidden md:block md:w-4/6 h-full items-center justify-center pt-10">
              <div className="mx-4 h-full">
                <img
                  src={login_crousal}
                  alt="People working"
                  className="object-cover h-full w-full rounded-xl md:rounded-3xl border-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="absolute left-10 right-10 md:left-14 md:right-14 border-t border-white/50 border-[0.5px]" />
      <section id="about-section">
        <About />
      </section>
      <section id="partnership-section">
        <Partnership />
      </section>
      <section id="team-section">
        <Team />
      </section>
      <section id="footer-section">
        <Footer />
      </section>
    </div>
  );
};
export default Login;

import React from "react";
import { Navigate } from "react-router-dom";
import getAuthState from "../hooks/getAuthState";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const isAuthenticated = getAuthState();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

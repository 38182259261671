import amber from "../../assets/amber.png";
import prodigy from "../../assets/prodigy.jpg";
import trip from "../../assets/trip.png";
import bokz from "../../assets/bokz.png";
import Uhomes from "../../assets/uhomes.png";
import { InfiniteMovingCards } from "../ui/infinite-moving-cards";

const companies = [
  {
    name: "Amber",
    logo: amber,
    link: "https://amberstudent.com/?utm_source=unimayt&utm_medium=website&utm_content=homepage",
  },
  {
    name: "Prodigy Finance",
    logo: prodigy,
    link: "https://prodigyfinance.com/?utm_source=unimayt&utm_medium=website&utm_content=homepage&utm_campaign=partners-unimayt-referral-2024",
  },
  {
    name: "Trip.com",
    logo: trip,
    link: "https://www.trip.com/partners/account/profile?utm_source=unimayt&utm_medium=website&utm_content=homepage",
  },
  {
    name: "Bokz",
    logo: bokz,
    link: "https://bokz.co.uk/product/the-essential/?company_name=Unimayt",
  },
  {
    name: "Uhomes",
    logo: Uhomes,
    link: "https://en.uhomes.com/referral/partnerShare?xcode=000ac268a5c7a3e821e7",
  },
];

const Partnership = () => {
  return (
    <>
      <div className="bg-unimaytLight lg:h-screen justify-center flex flex-col pt-8 lg:-mt-28 pb-6 lg:pb-0 ">
        <section>
          <div className="py-8 px-4  mx-auto w-72 gap-y-28  items-center flex flex-col md:w-[90dvh] text-center lg:pb-26 pt-4 lg:px-0">
            <div className="pt-2 lg:pt-0 w-screen md:w-full px-3 lg:px-0">
              {" "}
              <div className="relative flex items-center justify-center mb-8 lg:mb-6">
                <div className="absolute left-0 right-0 top-1/2 h-px bg-white"></div>
                <h2 className="relative z-10 bg-unimaytLight px-4 text-4xl lg:text-6xl font-bold whitespace-nowrap text-unimayt">
                  Our Partners
                </h2>
              </div>
              <div className="mx-auto -mb-16 max-w-screen-sm">
                <div className="text-white sm:text-xl">
                  Explore our current partnerships and discover how we are
                  shaping the future through shared vision and teamwork.
                </div>
              </div>
            </div>

            <div className="rounded-md md:w-[90dvh] w-96 flex flex-col antialiased items-center justify-center relative overflow-x-hidden">
              <InfiniteMovingCards
                items={companies}
                direction="right"
                pauseOnHover={false}
                speed="normal"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Partnership;
